<template>
  <div
    id="toast"
    class="toast-notification"
    :class="[
      { 'toast-show': isShow, 'toast-hide': !isShow },
      'toast-' + typeError,
    ]"
  >
    <span>
      <i
        class="zmdi"
        :class="typeError == 'error' ? 'zmdi-alert-polygon' : 'zmdi-info'"
      ></i>
      {{ message }}
    </span>
  </div>
</template>

<script>
export default {
  name: "Toast",
  data() {
    return {
      message: "",
      isShow: false,
      typeError: null,
      idTimeout: null,
    };
  },

  mounted() {
    this.$bus.$on("show-toast", this.showToast);
  },

  methods: {
    showToast(type, msg, time = 5000) {
      let self = this;

      //Oculto el toast si se está mostrando, pero no muestro nada nuevo
      if (msg == "" || time < 0) {
        self.isShow = false;
        self.idTimeout = null;
        return;
      }

      //Normalizo el type para que el default sea 'info'
      if (
        !type ||
        (type !== "info" && type !== "success" && type !== "error")
      ) {
        type = "info";
      }

      self.typeError = type;
      self.message = msg;
      self.isShow = true;

      if (self.idTimeout) {
        //Ya había un cartel programado para desaparecer con setTimeout.
        //Cancelo esa acción.
        clearTimeout(self.idTimeout);
      }

      //time = 0 es para que se muestre infinitamente
      if (time !== 0) {
        self.idTimeout = setTimeout(function () {
          self.isShow = false;
          self.idTimeout = null;
        }, time);
      }
    },
  },
};
</script>
